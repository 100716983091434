import logoImage from "../assets/img/unisot-logo-horizontal.png";
import miniLogo from "../assets/img/miniLogo.png";

import i18n from "i18next";

const tokenStorage = {
  cookie: "COOKIE",
  localStorage: "LOCAL_STORAGE",
};
let tokenStorageType = tokenStorage.localStorage;

const productName = "UNISOT SDC";
const logoText = "UNISOT";
const logoURL = "https://unisot.com";
const activePricingPerOperation = false;

const production = true;
const testDockerBuild = false;
const validateUserDocuments = true;
const useTestnet = false;
const enableS3Storage = false;
const enableGoogleStorage = false;

const environmentTypes = {
  development: "Development",
  test: "Test",
  production: "Production",
  abendum: "Abendum",
};

const instanceId = "5f0e20d6dcf33c70405bc1dc";
let idFrontendURL = "http://localhost:3500";
let idBackendURL = "http://localhost:4000/api";
let serviceFrontendURL = "http://localhost:3000";
let serviceBackendURL = "http://localhost:5000/api";
let blockchainInfoURL = "https://whatsonchain.com/tx/{{transactionId}}";
let environment = environmentTypes.development;
let llmBackendURL = "http://localhost:8000/api";
let llmAudioBackendURL = "http://localhost:8000/api";

if (production) {
  idFrontendURL = "https://id.unisot.ai";
  serviceFrontendURL = "https://sdc.unisot.ai";
  idBackendURL = "https://id.unisot.ai/api";
  serviceBackendURL = "https://sdc.unisot.ai/api";
  environment = environmentTypes.production;
  llmBackendURL = "https://llm.unisot.ai/api";
  llmAudioBackendURL = "https://llmaudio.unisot.ai/api";
} else {
  if (testDockerBuild) {
    idBackendURL = "http://localhost:3500/api";
    serviceBackendURL = "http://localhost:3000/api";
    tokenStorageType = tokenStorage.localStorage;
  }
}
if (useTestnet) {
  blockchainInfoURL = "https://test.whatsonchain.com/tx/{{transactionId}}";
}

const version = "1.0.1";

const themes = {
  unisot: "UNISOT",
  ibm: "IBM",
};

const theme = themes.unisot;

const storageFormat = "base64";
const messageTimeout = 6000;
const queryBackendInterval = 500;
const defaultPageSize = 10;
const defaultStorageDays = 30;

const languageTranslations = [
  { id: "en", token: "english" },
  //{ id: "es", token: "spanish" },
  //{ id: "de", token: "german" },
  //{ id: "zh", token: "chinese" },
];

const capabilities = {
  sdcDashboardView: "sdc_dashboard_view",
  sdcMyFilesView: "sdc_my_files_view",
  sdcSharedPackagesView: "sdc_shared_packages_view",
  sdcPublishedPackagesView: "sdc_published_packages_view",
  sdcReceivedPackagesView: "sdc_received_packages_view",
  sdcPendingPackagesView: "sdc_pending_packages_view",
  sdcAcceptedPackagesView: "sdc_accepted_packages_view",
  sdcRejectedPackagesView: "sdc_rejected_packages_view",
  sdcSharedAccessPackagesView: "sdc_shared_access_packages_view",
  sdcSharedItemsView: "sdc_shared_items_view",
  sdcContainersView: "sdc_containers_view",
  sdcMyContainersView: "sdc_my_containers_view",
  sdcSharedContainersView: "sdc_shared_containers_view",
  sdcLookupsView: "sdc_lookups_view",
  sdcJobsView: "sdc_jobs_view",
  sdcPricingView: "sdc_pricing_view",
  sdcPackageDetailsView: "sdc_package_details_view",
  sdcContainerDetailsView: "sdc_container_details_view",
  llmProcessing: "llm_processing",
  sdcAiView: "sdc_ai_view",
  sdcAiAudioProcessingView: "sdc_ai_audio_processing_view",
};

const isCapable = (profile, capability) => {
  return profile?.capabilities?.some((cap) => cap.name === capability);
};

const getFormattedUserName = (userId, userProfile) => {
  return userId !== userProfile._id
    ? `${i18n.t("for")} ${userProfile.name}`
    : "";
};

const methods = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  patch: "PATCH",
};

const contentTypes = {
  json: "application/json",
  formData: "form-data",
  searchParams: "search-parameters",
  fileDownload: "file-download",
};

const urlType = {
  id: "ID SERVICE",
  service: "BACKEND SERVICE",
  llm: "LLM SERVICE",
  llmAudio: "LLM_AUDIO",
};

const jobStatus = {
  failed: "FAILED",
  failedBlockchain: "FAILED_BLOCKCHAIN",
  done: "DONE",
};

const jobTransactionStatus = {
  prepared: "PREPARED",
  written: "WRITTEN",
};

const packageStatus = {
  sent: "SENT",
  read: "READ",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const shareItemStatus = {
  pending: "PENDING",
  shared: "SHARED",
  failed: "FAILED",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const documentContainerStatus = {
  stored: "STORED",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const packageState = {
  pending: "PENDING",
  accepted: "ACCEPTED",
  rejected: "REJECTED",
};

const packageAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const shareItemAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const documentContainerAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const signatureAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const packageStorageService = {
  cassandra: "CASSANDRA",
  blockchain: "BLOCKCHAIN",
};

const shareItemStorageService = {
  cassandra: "CASSANDRA",
  blockchain: "BLOCKCHAIN",
};
if (enableS3Storage) {
  shareItemStorageService.s3 = "S3";
}
if (enableGoogleStorage) {
  shareItemStorageService.googleStorage = "GOOGLE_STORAGE";
}

const shareItemDataType = {
  json: "application/json",
  xml: "text/xml",
  string: "string",
};

const shareItemResolvingType = {
  item: "item",
  itemTemplate: "itemTemplate",
};

const parentTypes = {
  package: "PACKAGE",
  shareItem: "SHARE_ITEM",
  container: "CONTAINER",
  metadata: "METADATA",
};

const metadataTypes = {
  json: "application/json",
  xml: "text/xml",
  string: "string",
};

const accessRightsTypes = {
  read: "READ",
  invite: "INVITE",
  sign: "SIGN",
};

const accessRightsStatus = {
  active: "ACTIVE",
  expired: "EXPIRED",
};

const serviceTypes = {
  sdc: "SDC",
  ubn: "UBN",
};

const keyTypes = {
  master: "MASTER",
};

const documentTypes = {
  avatar: "AVATAR",
};

const documentCriteria = {
  documentType: "byType",
};

const lookupTypes = {
  image: "IMAGE",
  url: "URL",
  state: "STATE",
  template: "TEMPLATE",
};

const lookupPermissionTypes = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const subscriptionTypes = {
  sdc: "SDC",
  ubn: "UBN",
  uie: "UIE",
  paymail: "PAYMAIL",
};

const subscriptionPlans = {
  operation: "PER OPERATION",
  monthly: "MONTHLY",
  yearly: "YEARLY",
};

const subscriptionPaymentMethods = {
  invoice: "INVOICE",
};

const subscriptionStatuses = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  cancelled: "CANCELLED",
};

const blockchainTypes = {
  bsv: "BSV",
  bsvDid: "BSV_DID",
  bsvEbsi: "BSV_EBSI",
  bsvDidEbsi: "BSV_DID_EBSI",
};

const reportingFieldTypes = {
  text: "TEXT",
  date: "DATE",
  signature: "SIGNATURE",
};

const exactMatch = [
  "parentId",
  "fileSize",
  "_id",
  "shareableFormatted",
  "blockchainStoreFormatted",
];

const endpoints = {
  readUser: {
    method: methods.get,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPublicKey: {
    method: methods.get,
    path: "users/keys",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getAllUsers: {
    method: methods.get,
    path: "users/keys/sdc",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPublicShareUser: {
    method: methods.get,
    path: "users/publicShare",
    status: 200,
    urlType: urlType.id,
  },
  getUserDocuments: {
    method: methods.get,
    path: "users/documents",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  readUserDocument: {
    method: methods.get,
    path: "users/documents/{{documentCriteria}}/base64",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.id,
  },
  monitorHealth: {
    method: methods.get,
    path: "monitor/health",
    status: 200,
    urlType: urlType.service,
  },
  getUserJobsCount: {
    method: methods.get,
    path: "job/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getUserJobs: {
    method: methods.get,
    path: "job/filter",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getLatestJobStatus: {
    method: methods.get,
    path: "job/{{jobId}}/latest",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getLatestPublicJobStatus: {
    method: methods.get,
    path: "package/contents/job/{{jobId}}/latest",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  uploadFile: {
    method: methods.post,
    path: "storage/store/file",
    status: 200,
    contentType: contentTypes.formData,
    urlType: urlType.service,
  },
  shareFile: {
    method: methods.post,
    path: "storage/share/file",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteFile: {
    method: methods.delete,
    path: "storage/delete/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getSendData: {
    method: methods.get,
    path: "storage/send/files",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getReceivedData: {
    method: methods.get,
    path: "storage/received/files",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getReceivedDataCount: {
    method: methods.get,
    path: "storage/received/files/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  prepareDownloadFile: {
    method: methods.get,
    path: "storage/prepare/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  downloadFile: {
    method: methods.get,
    path: "storage/download/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  prepareDownloadReport: {
    method: methods.post,
    path: "signature/report",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  downloadReport: {
    method: methods.get,
    path: "signature/download/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  getPackageDetails: {
    method: methods.get,
    path: "storage/details/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPackageSignature: {
    method: methods.get,
    path: "storage/verify/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPackageDecryptionKey: {
    method: methods.get,
    path: "storage/decryptionKey",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPackageTransactionChunks: {
    method: methods.get,
    path: "storage/transactionChunks",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addPackageComment: {
    method: methods.patch,
    path: "storage/store/comment",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deletePackageComment: {
    method: methods.delete,
    path: "storage/store/comment",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updatePackageParentId: {
    method: methods.patch,
    path: "storage/update/parentId",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageShareable: {
    method: methods.patch,
    path: "storage/update/shareable",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageState: {
    method: methods.patch,
    path: "storage/update/state",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageTTL: {
    method: methods.patch,
    path: "storage/update/ttl",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  getSendDataCount: {
    method: methods.get,
    path: "storage/send/files/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPublicPackageDetails: {
    method: methods.get,
    path: "package/details/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  getPublicPackageSignature: {
    method: methods.get,
    path: "package/verify/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  getPublicPackageDecryptionKey: {
    method: methods.get,
    path: "package/decryptionKey/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  getPublicPackageTransactionChunks: {
    method: methods.get,
    path: "package/transactionChunks/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  preparePublicDownloadPackageFile: {
    method: methods.get,
    path: "package/contents/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  downloadPublicFile: {
    method: methods.get,
    path: "package/contents/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  getSharedItems: {
    method: methods.get,
    path: "share/shared/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getSharedItemsCount: {
    method: methods.get,
    path: "share/shared/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getShareItem: {
    method: methods.get,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.service,
  },
  addShareItem: {
    method: methods.post,
    path: "share",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteShareItem: {
    method: methods.delete,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.service,
  },
  updateShareItem: {
    method: methods.patch,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.service,
  },
  downloadShareItem: {
    method: methods.get,
    path: "share/{{shareId}}/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
  },
  downloadShareItemCertificate: {
    method: methods.get,
    path: "share/snapshotCertificate/{{shareId}}/{{shareSecret}}",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
  },
  downloadQRCodes: {
    method: methods.get,
    path: "share/superuser/generate/qrCodes",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  getDocumentContainers: {
    method: methods.get,
    path: "documentContainer/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainersCount: {
    method: methods.get,
    path: "documentContainer/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainer: {
    method: methods.get,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainerQrCode: {
    method: methods.get,
    path: "documentContainer/qrCode",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getShareItemQrCode: {
    method: methods.get,
    path: "share/details/qrCode",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPackageQrCode: {
    method: methods.get,
    path: "storage/qrCode",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addDocumentContainer: {
    method: methods.post,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteDocumentContainer: {
    method: methods.delete,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updateDocumentContainer: {
    method: methods.patch,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  addDocumentContainerComment: {
    method: methods.patch,
    path: "documentContainer/comment",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteDocumentContainerComment: {
    method: methods.delete,
    path: "documentContainer/comment",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addMetadata: {
    method: methods.post,
    path: "metadata",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteMetadata: {
    method: methods.delete,
    path: "metadata",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  downloadMetadata: {
    method: methods.get,
    path: "metadata/{{metadataId}}/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
  },
  addAccessRights: {
    method: methods.post,
    path: "accessRights",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  inviteAccessRights: {
    method: methods.post,
    path: "accessRights/invite",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteAccessRights: {
    method: methods.delete,
    path: "accessRights",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addReportingField: {
    method: methods.post,
    path: "reportingField",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteReportingField: {
    method: methods.delete,
    path: "reportingField",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addSignature: {
    method: methods.post,
    path: "signature",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updateSignature: {
    method: methods.patch,
    path: "signature",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteSignature: {
    method: methods.delete,
    path: "signature",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  verifySignature: {
    method: methods.get,
    path: "signature/verify",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPricingPlans: {
    method: methods.get,
    path: "users/pricingPlans",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getUserServices: {
    method: methods.get,
    path: "users/services",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPricingPerOperation: {
    method: methods.get,
    path: "pricing",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getAccessRights: {
    method: methods.get,
    path: "accessRights/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getAccessRightsCount: {
    method: methods.get,
    path: "accessRights/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  deleteLookup: {
    method: methods.delete,
    path: "lookup/single",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  upsertLookup: {
    method: methods.post,
    path: "lookup",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  getSingleLookup: {
    method: methods.get,
    path: "lookup/single",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getLookups: {
    method: methods.get,
    path: "lookup",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  downloadLookups: {
    method: methods.get,
    path: "lookup",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  logoutUser: {
    method: methods.post,
    path: "users/logout",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  readSystemConfiguration: {
    method: methods.get,
    path: "config/superuser/read",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updateSystemConfiguration: {
    method: methods.post,
    path: "config/superuser/update",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  shutdownSystemService: {
    method: methods.patch,
    path: "config/superuser/shutdown",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  llmProcessAudio: {
    method: methods.post,
    path: "process/audio",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.llmAudio,
  },
  llmProcessQuestion: {
    method: methods.post,
    path: "process/question",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.llm,
  },
  llmRetrieveChatHistory: {
    method: methods.post,
    path: "process/chatHistory",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.llm,
  },
  llmProcessChatSummary: {
    method: methods.post,
    path: "process/chatSummary",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.llm,
  },
};

const localStorageSessionTokenKey = "userIdToken";

const getRedirectUri = (
  uri,
  serviceType = undefined,
  endpointName = undefined,
  userId = undefined,
  superuser = false
) => {
  let redirectUri = uri;
  const queryParams = {};
  let paramSet = false;
  if ([serviceTypes.sdc, serviceTypes.ubn].includes(serviceType)) {
    redirectUri = uri.endsWith("/") ? uri : `${uri}/`;
    redirectUri = `${redirectUri}auth/redirect-page`;
    if (serviceType === serviceTypes.ubn && endpointName) {
      queryParams.name = endpointName;
      paramSet = true;
    }
  }
  if (superuser && userId) {
    queryParams.userId = userId;
    paramSet = true;
  }
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
      paramSet = true;
    }
  }
  if (paramSet) {
    const formattedQueryParams = new URLSearchParams(queryParams).toString();
    redirectUri = `${redirectUri}?${formattedQueryParams}`;
  }
  return redirectUri;
};

const getSessionToken = () => {
  const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
  if (sessionToken) {
    return sessionToken;
  }
  throw new Error(i18n.t("session-token-does-not-exist"));
};

const storeSessionToken = (token) => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.setItem(localStorageSessionTokenKey, token);
  }
};

const removeSessionToken = () => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.removeItem(localStorageSessionTokenKey);
  }
};

const populatePath = (urlPath, params) => {
  let populatedPath = urlPath;
  const processingParameters = Object.entries(params);
  const paramsToProcess = {};
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of processingParameters) {
    const token = `{{${key}}}`;
    if (urlPath.includes(token)) {
      populatedPath = populatedPath.split(token).join(value);
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    populatedPath,
    paramsToProcess,
  };
};

const splitParamFields = (params, fields) => {
  const paramsFields = {};
  const paramsToProcess = {};
  const processingParameters = Object.entries(params);
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of processingParameters) {
    if (fields.includes(key)) {
      paramsFields[key] = value;
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    paramsFields,
    paramsToProcess,
  };
};

const apiRequest = async (
  endpoint,
  signal = undefined,
  params = {},
  authorize = true,
  exportSessionToken = false
) => {
  const { method, path, status, populate } = endpoint;
  let populatedPath = path;
  let paramsToProcess = params;
  if (populate) {
    const pathPopulatingResult = populatePath(path, params);
    populatedPath = pathPopulatingResult.populatedPath;
    paramsToProcess = pathPopulatingResult.paramsToProcess;
  }

  // Construct API URL
  let baseURL;
  if (endpoint.urlType === urlType.id) {
    baseURL = idBackendURL;
  } else if (endpoint.urlType === urlType.service) {
    baseURL = serviceBackendURL;
  } else if (endpoint.urlType === urlType.llm) {
    baseURL = llmBackendURL;
  } else {
    baseURL = llmAudioBackendURL;
  }
  const url = new URL(`${baseURL}/${populatedPath}`);

  // Set method
  const request = {
    method,
  };
  if (signal) {
    request.signal = signal;
  }

  // Set headers
  const headers = {};
  if (endpoint.contentType) {
    if (endpoint.contentType === contentTypes.json) {
      headers["Content-Type"] = endpoint.contentType;
      request.body = JSON.stringify(paramsToProcess);
    } else if (endpoint.contentType === contentTypes.formData) {
      const formData = new FormData();
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(paramsToProcess)) {
        formData.append(key, value);
      }
      request.body = formData;
    } else if (
      endpoint.contentType === contentTypes.searchParams ||
      endpoint.contentType === contentTypes.fileDownload
    ) {
      url.search = new URLSearchParams(paramsToProcess).toString();
    }
  }
  if (tokenStorageType === tokenStorage.cookie) {
    request.credentials = "include";
  } else if (tokenStorageType === tokenStorage.localStorage) {
    if (authorize) {
      const sessionToken = getSessionToken();
      headers["authorization"] = `Bearer ${sessionToken}`;
    }
  }
  if (Object.keys(headers).length > 0) {
    request.headers = headers;
  }

  const response = await fetch(url, request);
  let responseData;
  if (
    response.status === status &&
    endpoint.contentType === contentTypes.fileDownload
  ) {
    responseData = await response.blob();
  } else {
    responseData = await response.json();
  }
  if (response.status !== status) {
    throw new Error(responseData.message);
  }
  if (exportSessionToken) {
    storeSessionToken(responseData.token);
  }
  return responseData;
};

const isUserValidated = (userProfile, validateSuperuser = false) => {
  if (validateSuperuser) return userProfile.superuser;
  return (
    !userProfile.blocked &&
    userProfile.validatedEmail &&
    (!validateUserDocuments || userProfile.validatedDocuments)
  );
};

const getRedirectPath = (redirectPath) => {
  const redirectUri = `${idFrontendURL}/auth/redirect-page`;
  const queryParams = {
    redirectPath,
  };
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
    }
  }
  const formattedQueryParams = new URLSearchParams(queryParams).toString();
  return `${redirectUri}?${formattedQueryParams}`;
};

const getUserProfileViewPath = () => {
  return getRedirectPath("/admin/user-profile");
};

const getLockProfileViewPath = () => {
  return getRedirectPath("/auth/lock-screen-page");
};

const getUpdatePricingPlanPage = () => {
  return getRedirectPath("/admin/user-subscriptions");
};

const getPricingPerOperationActive = (userProfile) => {
  return !!userProfile.subscriptions.find(
    (subscription) =>
      subscription.type === subscriptionTypes.sdc &&
      subscription.plan === subscriptionPlans.operation &&
      (subscription.status === subscriptionStatuses.active ||
        subscription.status === subscriptionStatuses.cancelled)
  );
};

const loginRequired = async (
  signal,
  redirectNotValidated,
  validateSuperuser
) => {
  try {
    const userProfile = await apiRequest(endpoints.readUser, signal);
    if (
      redirectNotValidated &&
      !isUserValidated(userProfile, validateSuperuser)
    ) {
      window.location.replace(getUserProfileViewPath());
    }
    return userProfile;
  } catch (e) {
    if (signal?.aborted) {
      return console.error(e);
    }
    removeSessionToken();
    const url = new URL(`${idFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      deploymentId: instanceId,
      redirectPath: "/admin/dashboard",
    });
    window.location.replace(url.toString());
  }
};

const queryLatestJobStatus = async (
  endpoint,
  signal,
  params,
  authorize = true
) => {
  await new Promise((r) => setTimeout(r, queryBackendInterval));
  const responseData = await apiRequest(endpoint, signal, params, authorize);
  return responseData;
};

const getShareFrontendUrl = () => {
  const url = new URL(`${idFrontendURL}/auth/login-page`);
  url.search = new URLSearchParams({
    deploymentId: instanceId,
    redirectPath: `/admin/package`,
  });
  return url.toString();
};

const checkValidLink = (data) => {
  if (typeof data === "string") {
    return /^((http|https|ftp):\/\/)/.test(data);
  }
  return false;
};

const formatPricing = (price, currency) => {
  return `${price.toFixed(2)} ${currency}`;
};

const isObjectEmpty = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

const ApiService = {
  environment,
  activePricingPerOperation,
  instanceId,
  storeSessionToken,
  getRedirectUri,
  populatePath,
  getUserProfileViewPath,
  getLockProfileViewPath,
  getUpdatePricingPlanPage,
  blockchainInfoURL,
  idFrontendURL,
  serviceFrontendURL,
  storageFormat,
  productName,
  logoText,
  logoImage,
  miniLogo,
  logoURL,
  messageTimeout,
  defaultPageSize,
  defaultStorageDays,
  jobStatus,
  jobTransactionStatus,
  packageState,
  packageAccess,
  packageStatus,
  shareItemAccess,
  shareItemStatus,
  documentContainerStatus,
  documentContainerAccess,
  signatureAccess,
  packageStorageService,
  shareItemStorageService,
  shareItemDataType,
  shareItemResolvingType,
  parentTypes,
  metadataTypes,
  accessRightsTypes,
  accessRightsStatus,
  validateUserDocuments,
  documentTypes,
  documentCriteria,
  isUserValidated,
  getFormattedUserName,
  lookupTypes,
  lookupPermissionTypes,
  subscriptionTypes,
  subscriptionPlans,
  subscriptionPaymentMethods,
  subscriptionStatuses,
  blockchainTypes,
  reportingFieldTypes,
  exactMatch,
  getPricingPerOperationActive,
  getShareFrontendUrl,
  checkValidLink,
  formatPricing,
  formatDateTime: (input) => {
    if (!input) {
      return i18n.t("forever");
    }
    return input.replace(/[TZ]/g, " ").trim();
  },
  getValidPackageStates: (user, sender, receiver, currentPackageState) => {
    if (sender === receiver) {
      return [];
    }
    if (currentPackageState === packageState.pending && user === receiver) {
      return [packageState.accepted, packageState.rejected];
    }
    if (currentPackageState === packageState.rejected && sender === user) {
      return [packageState.pending];
    }
    return [];
  },
  loginRequired: async (
    signal = undefined,
    redirectNotValidated = true,
    validateSuperuser = false
  ) => {
    const responseData = await loginRequired(
      signal,
      redirectNotValidated,
      validateSuperuser
    );
    return responseData;
  },
  readUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.readUser, signal, params);
    return responseData;
  },
  getPublicKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPublicKey, signal, {
      deploymentId: instanceId,
      serviceType: serviceTypes.sdc,
      keyType: keyTypes.master,
      ...params,
    });
    if (responseData.length === 0) {
      throw new Error(i18n.t("user-does-not-have-registered-key"));
    }
    return responseData[0];
  },
  getAllUsers: async (
    params,
    signal = undefined,
    filterOnlyValidatedUsers = true,
    filterOutSpecialUsers = false,
    filterOutSuperUsers = true
  ) => {
    const responseData = await apiRequest(endpoints.getAllUsers, signal, {
      ...params,
      deploymentId: instanceId,
    });
    let filteredEntries = responseData;
    if (filterOnlyValidatedUsers) {
      filteredEntries = filteredEntries.filter((entry) =>
        isUserValidated(entry.user)
      );
    }
    if (filterOutSpecialUsers) {
      filteredEntries = filteredEntries.filter((entry) => !entry.user.special);
    }
    if (filterOutSuperUsers) {
      filteredEntries = filteredEntries.filter(
        (entry) => !entry.user.superuser
      );
    }
    return filteredEntries;
  },
  getPublicShareUser: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getPublicShareUser,
      signal,
      params,
      false
    );
    responseData.user = responseData;
    return responseData;
  },
  getUserDocuments: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserDocuments,
      signal,
      params
    );
    return responseData;
  },
  readUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.readUserDocument,
      signal,
      params
    );
    return responseData;
  },
  monitorHealth: async (signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.monitorHealth,
      signal,
      false
    );
    return responseData;
  },
  getUserJobsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserJobsCount,
      signal,
      params
    );
    return responseData;
  },
  getUserJobs: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserJobs,
      signal,
      params
    );
    return responseData;
  },
  queryLatestJobStatus: async (params, signal = undefined) => {
    const responseData = await queryLatestJobStatus(
      endpoints.getLatestJobStatus,
      signal,
      params
    );
    return responseData;
  },
  queryLatestPublicDownloadJobStatus: async (params, signal = undefined) => {
    const responseData = await queryLatestJobStatus(
      endpoints.getLatestPublicJobStatus,
      signal,
      params,
      false
    );
    return responseData;
  },
  uploadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.uploadFile, signal, params);
    return responseData;
  },
  shareFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.shareFile, signal, params);
    return responseData;
  },
  deleteFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteFile, signal, params);
    return responseData;
  },
  getSendData: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSendData,
      signal,
      params
    );
    return responseData.filter(
      (data) =>
        data.status !== packageStatus.deleted &&
        data.status !== packageStatus.expired
    );
  },
  getReceivedData: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getReceivedData,
      signal,
      params
    );
    return responseData.filter(
      (data) =>
        data.status !== packageStatus.deleted &&
        data.status !== packageStatus.expired
    );
  },
  getReceivedDataCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getReceivedDataCount,
      signal,
      params
    );
    return responseData;
  },
  prepareDownloadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.prepareDownloadFile,
      signal,
      params
    );
    return responseData;
  },
  downloadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadFile,
      signal,
      params
    );
    return responseData;
  },
  prepareDownloadReport: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.prepareDownloadReport,
      signal,
      params
    );
    return responseData;
  },
  downloadReport: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadReport,
      signal,
      params
    );
    return responseData;
  },
  getPackageDetails: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPackageDetails,
      signal,
      params
    );
    return responseData;
  },
  getPackageSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPackageSignature,
      signal,
      params
    );
    return responseData;
  },
  getPackageDecryptionKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPackageDecryptionKey,
      signal,
      params
    );
    return responseData;
  },
  getPackageTransactionChunks: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPackageTransactionChunks,
      signal,
      params
    );
    return responseData;
  },
  addPackageComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addPackageComment,
      signal,
      params
    );
    return responseData;
  },
  deletePackageComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deletePackageComment,
      signal,
      params
    );
    return responseData;
  },
  updatePackageParentId: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updatePackageParentId,
      signal,
      params
    );
    return responseData;
  },
  updatePackageShareable: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updatePackageShareable,
      signal,
      params
    );
    return responseData;
  },
  updatePackageState: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updatePackageState,
      signal,
      params
    );
    return responseData;
  },
  updatePackageTTL: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updatePackageTTL,
      signal,
      params
    );
    return responseData;
  },
  getSendDataCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSendDataCount,
      signal,
      params
    );
    return responseData;
  },
  getPublicPackageDetails: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicPackageDetails,
      signal,
      params,
      false
    );
    return responseData;
  },
  getPublicPackageSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicPackageSignature,
      signal,
      params,
      false
    );
    return responseData;
  },
  getPublicPackageDecryptionKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicPackageDecryptionKey,
      signal,
      params,
      false
    );
    return responseData;
  },
  getPublicPackageTransactionChunks: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicPackageTransactionChunks,
      signal,
      params,
      false
    );
    return responseData;
  },
  preparePublicDownloadPackageFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.preparePublicDownloadPackageFile,
      signal,
      params,
      false
    );
    return responseData;
  },
  downloadPublicFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadPublicFile,
      signal,
      params,
      false
    );
    return responseData;
  },
  getSharedItems: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSharedItems,
      signal,
      params
    );
    return responseData.filter(
      (data) =>
        data.status !== shareItemStatus.deleted &&
        data.status !== shareItemStatus.expired
    );
  },
  getSharedItemsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSharedItemsCount,
      signal,
      params
    );
    return responseData;
  },
  addShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addShareItem,
      signal,
      params
    );
    return responseData;
  },
  getShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getShareItem,
      signal,
      params
    );
    return responseData;
  },
  deleteShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteShareItem,
      signal,
      params
    );
    return responseData;
  },
  updateShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateShareItem,
      signal,
      params
    );
    return responseData;
  },
  downloadShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadShareItem,
      signal,
      params
    );
    return responseData;
  },
  downloadShareItemCertificate: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadShareItemCertificate,
      signal,
      params
    );
    return responseData;
  },
  downloadQRCodes: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadQRCodes,
      signal,
      params
    );
    return responseData;
  },
  getDocumentContainers: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDocumentContainers,
      signal,
      params
    );
    return responseData.filter(
      (data) =>
        data.status !== shareItemStatus.deleted &&
        data.status !== shareItemStatus.expired
    );
  },
  getDocumentContainersCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDocumentContainersCount,
      signal,
      params
    );
    return responseData;
  },
  getDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDocumentContainer,
      signal,
      params
    );
    return responseData;
  },
  getDocumentContainerQrCode: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDocumentContainerQrCode,
      signal,
      params
    );
    return responseData;
  },
  getShareItemQrCode: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getShareItemQrCode,
      signal,
      params
    );
    return responseData;
  },
  getPackageQrCode: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPackageQrCode,
      signal,
      params
    );
    return responseData;
  },
  addDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addDocumentContainer,
      signal,
      params
    );
    return responseData;
  },
  deleteDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteDocumentContainer,
      signal,
      params
    );
    return responseData;
  },
  updateDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateDocumentContainer,
      signal,
      params
    );
    return responseData;
  },
  addDocumentContainerComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addDocumentContainerComment,
      signal,
      params
    );
    return responseData;
  },
  deleteDocumentContainerComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteDocumentContainerComment,
      signal,
      params
    );
    return responseData;
  },
  addMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addMetadata,
      signal,
      params
    );
    return responseData;
  },
  deleteMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteMetadata,
      signal,
      params
    );
    return responseData;
  },
  downloadMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadMetadata,
      signal,
      params
    );
    return responseData;
  },
  addAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addAccessRights,
      signal,
      params
    );
    return responseData;
  },
  inviteAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.inviteAccessRights,
      signal,
      params
    );
    return responseData;
  },
  deleteAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteAccessRights,
      signal,
      params
    );
    return responseData;
  },
  addReportingField: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addReportingField,
      signal,
      params
    );
    return responseData;
  },
  deleteReportingField: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteReportingField,
      signal,
      params
    );
    return responseData;
  },
  addSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addSignature,
      signal,
      params
    );
    return responseData;
  },
  updateSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateSignature,
      signal,
      params
    );
    return responseData;
  },
  deleteSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteSignature,
      signal,
      params
    );
    return responseData;
  },
  verifySignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.verifySignature,
      signal,
      params
    );
    return responseData;
  },
  getPricingPlans: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPricingPlans,
      signal,
      params,
      false
    );
    return responseData;
  },
  getUserServices: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserServices,
      signal,
      params
    );
    return responseData;
  },
  getPricingPerOperation: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPricingPerOperation,
      signal,
      params
    );
    return responseData;
  },
  getAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getAccessRights,
      signal,
      params
    );
    return responseData;
  },
  getAccessRightsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getAccessRightsCount,
      signal,
      params
    );
    return responseData;
  },
  deleteLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteLookup,
      signal,
      params
    );
    return responseData;
  },
  upsertLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertLookup,
      signal,
      params
    );
    return responseData;
  },
  getSingleLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleLookup,
      signal,
      params
    );
    return responseData;
  },
  getLookups: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getLookups, signal, params);
    return responseData;
  },
  downloadLookups: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadLookups,
      signal,
      params
    );
    return responseData;
  },
  logoutUser: async (params, history, redirect = true, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, [
      "superuser",
    ]);
    const responseData = await apiRequest(
      endpoints.logoutUser,
      signal,
      paramsToProcess
    );
    if (!paramsFields.superuser) {
      removeSessionToken();
      if (redirect) {
        history.push("/");
      }
    }
    return responseData;
  },
  readSystemConfiguration: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.readSystemConfiguration,
      signal,
      params
    );
    return responseData;
  },
  updateSystemConfiguration: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateSystemConfiguration,
      signal,
      params
    );
    return responseData;
  },
  shutdownSystemService: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.shutdownSystemService,
      signal,
      params
    );
    return responseData;
  },
  llmProcessAudio: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.llmProcessAudio,
      signal,
      params
    );
    return responseData;
  },
  llmProcessQuestion: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.llmProcessQuestion,
      signal,
      params
    );
    return responseData;
  },
  llmRetrieveChatHistory: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.llmRetrieveChatHistory,
      signal,
      params
    );
    return responseData;
  },
  llmProcessChatSummary: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.llmProcessChatSummary,
      signal,
      params
    );
    return responseData;
  },
  isObjectEmpty,
  languageTranslations,
  version,
  themes,
  theme,
  capabilities,
  isCapable,
};

export default ApiService;
